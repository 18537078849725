import { UserSettings } from 'tripPlanner/types/common'

const THREE_DAYS_MILLISECONDS = 259200000
const MAX_TOTAL_CLOSURES = 3

interface TripReengagementDismissalState {
  isDismissed: boolean
  dismissedAt: number
  totalClosures: number
}

const initialState: TripReengagementDismissalState = {
  isDismissed: false,
  dismissedAt: 0,
  totalClosures: 0,
}

export function updateTripReengagementParams(
  type: 'closed' | 'clicked' | 'ignored',
  userSettings: UserSettings,
) {
  // Don't count clicks or ignores toward total closures
  if (type === 'ignored' || type === 'clicked') {
    return { tripReengagementDismissedAt: Date.now() }
  }

  const { tripReengagementTotalClosures } = userSettings
  const closures = tripReengagementTotalClosures ?? 0

  const now = Date.now()

  return {
    tripReengagementDismissedAt: now,
    tripReengagementTotalClosures: closures + 1,
  }
}

/**
 * If the trip reengagement card has been dismissed for at least 3 days the card will be shown again.
 * If they've dismissed it 3 times, it will never be shown again.
 */
export function getDismissalState(
  userSettings: UserSettings,
): TripReengagementDismissalState {
  const { tripReengagementDismissedAt, tripReengagementTotalClosures } =
    userSettings

  if (tripReengagementTotalClosures === undefined) {
    // Then it hasn't been dismissed yet... so we need to show the card
    return initialState
  }

  if (tripReengagementDismissedAt === undefined) {
    // This isn't a customer case, but instead is for the reset of dismissed time
    // in hidden settings while there are previous closures
    return { ...initialState, totalClosures: tripReengagementTotalClosures }
  }

  if (
    !isDismissed(tripReengagementDismissedAt) &&
    tripReengagementTotalClosures < MAX_TOTAL_CLOSURES
  ) {
    return {
      isDismissed: false,
      dismissedAt: tripReengagementDismissedAt,
      totalClosures: tripReengagementTotalClosures,
    }
  }

  return {
    isDismissed: true,
    dismissedAt: tripReengagementDismissedAt,
    totalClosures: tripReengagementTotalClosures,
  }
}

const isDismissed = (tripReengagementDismissedAt: number) => {
  return Date.now() - tripReengagementDismissedAt < THREE_DAYS_MILLISECONDS
}
