import {
  getCarHireOffers,
  getCarHireReservation,
  getCheapestVehicle,
  getInsuranceQuoteQuery,
  getOfferImportantInformation,
  getReservationInsuranceInfo,
  getDestinationsPriceInfo,
} from 'api/carHire'
import {
  FETCH_CAR_HIRE_CHEAPEST_OFFER,
  FETCH_CAR_HIRE_OFFERS,
  FETCH_CAR_HIRE_OFFER_IMPORTANT_INFORMATION,
  FETCH_CAR_HIRE_OFFER_INSURANCE_QUOTE,
  FETCH_CAR_HIRE_RESERVATION,
  FETCH_CAR_HIRE_RESERVATION_INSURANCE_INFO,
  FETCH_CAR_HIRE_DESTINATION_PRICE_INFO,
} from './apiActionConstants'
import { API_CALL } from './actionConstants'
import { findCarHireListKey, getCarHireListKey } from 'components/CarHire/carHireUtils'
import { CAR_HIRE_AVAILABILITY_CACHE_EXPIRATION_MS, CAR_HIRE_INSURANCE_CACHE_EXPIRATION_MS } from 'constants/carHire'

export function fetchCarHireOfferList(filter: App.CarHireOfferListFilters) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const currencyCode = state.geo.currentCurrency
    const currentRegionCode = state.geo.currentRegionCode
    const key = getCarHireListKey(filter)

    const existingList = findCarHireListKey(state.carHire.offerLists, key)
    if (existingList && (existingList.fetching || (existingList.timestamp && existingList.timestamp > (Date.now() - CAR_HIRE_AVAILABILITY_CACHE_EXPIRATION_MS)))) return

    dispatch({
      type: API_CALL,
      api: FETCH_CAR_HIRE_OFFERS,
      request: () => getCarHireOffers(filter, currencyCode, currentRegionCode),
      key,
    })
  }
}

export function fetchCheapestCarHireOffer(filter: App.CarHireOfferListFilters) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const currencyCode = state.geo.currentCurrency
    const currentRegionCode = state.geo.currentRegionCode
    const key = getCarHireListKey(filter)

    const existingList = state.carHire.cheapestOfferLists[key]
    if (existingList && (existingList.fetching || (existingList.timestamp && existingList.timestamp > (Date.now() - CAR_HIRE_AVAILABILITY_CACHE_EXPIRATION_MS)))) return

    dispatch({
      type: API_CALL,
      api: FETCH_CAR_HIRE_CHEAPEST_OFFER,
      request: () => getCheapestVehicle(filter, currencyCode, currentRegionCode),
      key,
    })
  }
}

export function fetchCarHireReservation(reservationId: string, purchasingCustomerId?: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const customerId = purchasingCustomerId || state.auth.account.memberId || ''

    const existingList = state.carHire.reservationInfo[reservationId]
    if (existingList) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_CAR_HIRE_RESERVATION,
      key: reservationId,
      request: () => getCarHireReservation(reservationId, customerId),
    })
  }
}

export function fetchCarHireOfferImportantInfo(params: App.CarHireOfferImportantInformationParams) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const currencyCode = state.geo.currentCurrency
    const currentRegionCode = state.geo.currentRegionCode
    const key = params.reference.id

    const existingList = state.carHire.offerImportantInformationLists[key]
    if (existingList) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_CAR_HIRE_OFFER_IMPORTANT_INFORMATION,
      request: () => getOfferImportantInformation(params, currencyCode, currentRegionCode),
      key,
    })
  }
}

export function fetchReservationInsuranceInfo(reservationId: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const existingList = state.carHire.reservationInsuranceInfo[reservationId]
    if (existingList) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_CAR_HIRE_RESERVATION_INSURANCE_INFO,
      key: reservationId,
      request: () => getReservationInsuranceInfo(reservationId),
    })
  }
}

export function fetchCarHireOfferInsuranceQuote(params: App.CarHireOfferInsuranceQuoteParams) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const currencyCode = state.geo.currentCurrency
    const currentRegionCode = state.geo.currentRegionCode
    const key = params.reference.id

    const existingList = state.carHire.offerInsuranceQuoteLists[key]
    if (existingList && (existingList.fetching || (existingList.timestamp && existingList.timestamp > (Date.now() - CAR_HIRE_INSURANCE_CACHE_EXPIRATION_MS)))) return

    dispatch({
      type: API_CALL,
      api: FETCH_CAR_HIRE_OFFER_INSURANCE_QUOTE,
      request: () => getInsuranceQuoteQuery(params, currencyCode, currentRegionCode),
      key,
    })
  }
}

export function fetchDestinationPriceInfo(params: App.CarHireDestinationPriceInfoParams) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const key = params.key
    const currencyCode = state.geo.currentCurrency

    const existingList = state.carHire.destinationsPriceInfoList[key]
    if (existingList) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_CAR_HIRE_DESTINATION_PRICE_INFO,
      request: () => getDestinationsPriceInfo(params, currencyCode),
      key,
    })
  }
}
