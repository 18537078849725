import React, { useEffect, useMemo, useRef } from 'react'

import IconInCircle from '../Icons/IconInCircle'

import StickyPromptCard from 'components/Common/StickyPromptCard/StickyPromptCard'
import StickyPromptCardContent from 'components/Common/StickyPromptCard/StickyPromptCardContent'
import StickyPromptCardFooter from 'components/Common/StickyPromptCard/StickyPromptCardFooter'
import TextButton from 'components/Luxkit/Button/TextButton'
import AssistChip from 'components/Luxkit/Chips/AssistChip'
import SolidCheckIcon from 'components/Luxkit/Icons/solid/SolidCheckIcon'
import SolidExclamationTriangleIcon from 'components/Luxkit/Icons/solid/SolidExclamationTriangleIcon'
import SolidHeartIcon from 'components/Luxkit/Icons/solid/SolidHeartIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Caption from 'components/Luxkit/Typography/Caption'
import Group from 'components/utils/Group'
import { countWhere } from 'lib/array/arrayUtils'
import noop from 'lib/function/noop'
import { useTripLowStockItems, useTrip } from 'tripPlanner/hooks/api'
import {
  useUpdateUserSettings,
  useUserSettings,
} from 'tripPlanner/hooks/api/userSettings'
import { BasicTrip } from 'tripPlanner/types/common'
import { formatDateRange, getTripURL, isCustomItem, itemIsBooked } from 'tripPlanner/utils'
import { updateTripReengagementParams } from 'tripPlanner/utils/tripReengagement'
import { tripItemSelectionId } from 'tripPlanner/utils/itemSelection'

interface Props {
  trip: BasicTrip
  onClose?: () => void
  show: boolean
  stickyTop?: number
}

function TripReengagementCard({
  trip: basicTrip,
  onClose = noop,
  show,
  stickyTop,
}: Props) {
  const { data: fullTrip } = useTrip({
    tripId: basicTrip.id,
    enabled: show,
  })

  const { data: lowStockTripItem } = useTripLowStockItems({
    tripId: basicTrip.id,
  })

  const cardImage = useMemo<App.Image>(
    () => ({
      id: basicTrip.imageId,
      url: basicTrip.imageUrl,
    }),
    [basicTrip.imageId, basicTrip.imageUrl],
  )

  // This must be defined or else this component would not have rendered to begin with.
  const { data: userSettings } = useUserSettings()

  const { mutate: updateUserSettings } = useUpdateUserSettings()

  // If the card is closed by interaction, we don't want to trigger the ignored action
  // (Which occurs when this component unmounts as you can see in the useEffect)
  const closedByInteraction = useRef(false)

  useEffect(() => {
    return () => {
      if (show && !closedByInteraction) {
        updateUserSettings(
          updateTripReengagementParams('ignored', userSettings!),
        )
      }
    }
  }, [show, updateUserSettings, userSettings])

  const onClick = () => {
    closedByInteraction.current = true
    updateUserSettings(updateTripReengagementParams('clicked', userSettings!))
    onClose()
  }

  const onDismiss = () => {
    closedByInteraction.current = true
    updateUserSettings(updateTripReengagementParams('closed', userSettings!))
    onClose()
  }

  let bookedSavedContent: React.ReactNode = null

  if (fullTrip) {
    const bookedCount = countWhere(fullTrip.items, itemIsBooked)
    const savedCount = countWhere(
      fullTrip.items,
      (item) => !isCustomItem(item) && !itemIsBooked(item),
    )

    bookedSavedContent = (
      <>
        <Group direction="horizontal" gap={8} verticalAlign="center">
          <IconInCircle
            icon={SolidCheckIcon}
            size={16}
            iconSize={13}
            circleColour={bookedCount > 0 ? 'success' : 'neutral-four'}
          />
          <BodyText variant="medium">{bookedCount} booked</BodyText>
        </Group>
        <Group direction="horizontal" gap={8} verticalAlign="center">
          <IconInCircle
            icon={SolidHeartIcon}
            size={16}
            iconSize={10}
            circleColour={savedCount > 0 ? 'favourite' : 'neutral-four'}
          />
          <BodyText variant="medium">{savedCount} saved</BodyText>
        </Group>
      </>
    )
  }

  return (
    <StickyPromptCard
      title="Keep planning your trip"
      onClose={onDismiss}
      to={getTripURL(basicTrip.id)}
      onClick={onClick}
      show={show}
      stickyTop={stickyTop}
    >
      <StickyPromptCardContent image={cardImage} imageFit="center">
        <Group direction="vertical" gap={8}>
          <BodyText variant="large" lineClamp={2}>
            {basicTrip.name}
          </BodyText>
          {basicTrip.startDate && basicTrip.endDate && (
            <Caption variant="large">
              {formatDateRange(basicTrip.startDate, basicTrip.endDate)}
            </Caption>
          )}
          {lowStockTripItem && (
            <Group direction="horizontal" gap={16}>
              <AssistChip
                variant="filled"
                size="small"
                to={`${getTripURL(lowStockTripItem.tripId)}?#${tripItemSelectionId(lowStockTripItem.id)}`}
                endIcon={<SolidExclamationTriangleIcon colour="warning" />}
                onClick={onClick}
              >
                Limited availability
              </AssistChip>
            </Group>
          )}
        </Group>
      </StickyPromptCardContent>
      <StickyPromptCardFooter
        callToAction={
          <TextButton
            variant="default"
            kind="primary"
            size="small"
            nonInteractable
          >
            Continue planning
          </TextButton>
        }
      >
        {bookedSavedContent}
      </StickyPromptCardFooter>
    </StickyPromptCard>
  )
}

export default TripReengagementCard
