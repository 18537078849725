import { fetchCheapestCarHireOffer } from 'actions/CarHireActions'
import { getCarHireListKey } from 'components/CarHire/carHireUtils'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { useEffect, useMemo } from 'react'

const EmptyList: App.CarHireCheapestOfferList = {
  offerSummary: null,
  error: undefined,
  fetching: false,
}

function useCarHireList(filters: App.CarHireOfferListFilters) {
  const dispatch = useAppDispatch()
  const listKey = useMemo(() => {
    return getCarHireListKey(filters)
  }, [filters])

  const list = useAppSelector(state => state.carHire.cheapestOfferLists[listKey]) ?? EmptyList

  useEffect(() => {
    const { pickUpDate, dropOffDate, dropOffTime, pickUpTime, pickUpLocationId } = filters
    if (pickUpTime && dropOffTime && dropOffDate && pickUpDate && pickUpTime && pickUpLocationId) {
      dispatch(fetchCheapestCarHireOffer(filters))
    }
  }, [dispatch, filters, listKey])

  return list
}

export default useCarHireList
