import Image from 'components/Common/Image'
import { rem } from 'polished'
import React, { ComponentProps, PropsWithChildren } from 'react'
import styled from 'styled-components'

export const StickyCardImageWidth = 80

const ContentContainer = styled.div`
  display: grid;
  grid-template: "content image" auto / 1fr ${rem(StickyCardImageWidth)};
  gap: ${rem(12)};
`

const ContentArea = styled.div`
  grid-area: content;
`

const ImageArea = styled(Image)`
  grid-area: image;
  max-width: ${rem(StickyCardImageWidth)};  // do not remove, this decides the image rendering size
  max-height: ${rem(StickyCardImageWidth)};
`

type Props = PropsWithChildren<{
  image: App.Image
  imageFit?: ComponentProps<typeof Image>['fit']
}>

function StickyPromptCardContent(props: Props) {
  const { children, image, imageFit = 'center' } = props

  return <ContentContainer>
    <ContentArea>{children}</ContentArea>
    <ImageArea
      image={image}
      fit={imageFit}
      width={StickyCardImageWidth} // do not remove, this decides the source image file size
      height={StickyCardImageWidth}
      aspectRatio="1"
    />
  </ContentContainer>
}

export default StickyPromptCardContent
