import React, { useMemo } from 'react'

import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import StickyPromptCard from 'components/Common/StickyPromptCard/StickyPromptCard'
import CaptionTextBlock from 'components/Luxkit/TextBlocks/CaptionTextBlock'
import BodyText from 'components/Luxkit/Typography/BodyText'
import noop from 'lib/function/noop'
import { CarHireReengagementView } from 'components/Common/StickyPromptCard/useCarHireReengagementSelectionLogic'
import { setCarHireReengagementDismissedInStorage } from 'storage/carHireReengagement'
import qs from 'qs'
import { pluralizeToString } from 'lib/string/pluralize'
import StickyPromptCardContent from 'components/Common/StickyPromptCard/StickyPromptCardContent'
import StickyPromptCardFooter from 'components/Common/StickyPromptCard/StickyPromptCardFooter'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import TextButton from 'components/Luxkit/Button/TextButton'

interface Props {
  onClose?: () => void
  show: boolean
  stickyTop?: number
  carHireReengagementView: CarHireReengagementView
  offerSummary: App.CarHireOfferSummary
}

function CarHireReengagementCard(props: Props) {
  const {
    carHireReengagementView,
    offerSummary,
    show,
    stickyTop,
    onClose = noop,
  } = props
  const onClick = () => {
    setCarHireReengagementDismissedInStorage('clicked')
    onClose()
  }

  const onDismiss = () => {
    setCarHireReengagementDismissedInStorage('closed')
    onClose()
  }

  const vehicleImage = useMemo(() => ({
    ...offerSummary.vehicle.image,
    // modify the image URL to utilise car trawlers image options for a better image
    // 'secondary' faces left, 'primary' faces right
    url: `${offerSummary.vehicle.image.url?.replace('primary', 'secondary')}?w=68&dpr=2`,
  }), [offerSummary])

  const queryParams = {
    pickUpName: carHireReengagementView.placeName,
    dropOffName: carHireReengagementView.placeName,
    pickUpId: carHireReengagementView.filters.pickUpLocationId,
    dropOffId: carHireReengagementView.filters.dropOffLocationId,
    pickUpDate: carHireReengagementView.filters.pickUpDate,
    pickUpTime: carHireReengagementView.filters.pickUpTime,
    dropOffDate: carHireReengagementView.filters.dropOffDate,
    dropOffTime: carHireReengagementView.filters.dropOffTime,
    ageCategory: carHireReengagementView.filters.driversAgeCategory,
    age: carHireReengagementView.filters.driversAge,
    pickUpSearchType: carHireReengagementView.filters.pickUpSearchType,
    dropOffSearchType: carHireReengagementView.filters.dropOffSearchType,
    isPickUpAirport: carHireReengagementView.filters.isPickUpAtAirport,
  }

  return (
    <StickyPromptCard
      title={`Need to hire a car in ${carHireReengagementView.placeName}?`}
      onClose={onDismiss}
      to={`/search/car-hire?${qs.stringify(queryParams)}`}
      onClick={onClick}
      show={show}
      stickyTop={stickyTop}
    >
      <StickyPromptCardContent image={vehicleImage}>
        <VerticalSpacer gap={4}>
          <BodyText weight="bold" variant="medium" lineClamp={2}>
            We’ve found {pluralizeToString('car', carHireReengagementView.offerCount)}
          </BodyText>
          <CaptionTextBlock variant="medium">
            {carHireReengagementView.pickUpLocationName} - {carHireReengagementView.dropOffLocationName}
          </CaptionTextBlock>
        </VerticalSpacer>
      </StickyPromptCardContent>
      <StickyPromptCardFooter
        callToAction={<TextButton
          variant="default"
          kind="primary"
          size="small"
          nonInteractable
        >
          View Cars
        </TextButton>}
      >
        <PriceRowPriceCaption>{offerSummary.duration} days from</PriceRowPriceCaption>
        <PriceRowPrice
          size="S"
          price={offerSummary.totalCharge.price}
          saleUnit="total"
        />
      </StickyPromptCardFooter>
    </StickyPromptCard>
  )
}

export default CarHireReengagementCard
