import { DISABLE_STICKY_PROMPT_CARD, ENABLE_STICKY_PROMPT_CARD } from './actionConstants'

type Actions = Utils.FullActionMap<{
  [ENABLE_STICKY_PROMPT_CARD]: {},
  [DISABLE_STICKY_PROMPT_CARD]: {},
}>

export function enableStickyPromptCard(): Actions['ENABLE_STICKY_PROMPT_CARD'] {
  return { type: ENABLE_STICKY_PROMPT_CARD }
}

export function disableStickyPromptCard(): Actions['DISABLE_STICKY_PROMPT_CARD'] {
  return { type: DISABLE_STICKY_PROMPT_CARD }
}

export function disableStickyPromptCardWithCooldown(cooldown: number) {
  return (dispatch) => {
    if (cooldown > 0) {
      // disable all card globally
      dispatch(disableStickyPromptCard())

      // re-enable it after cooldown time
      setTimeout(() => {
        dispatch(enableStickyPromptCard())
      }, cooldown)
    }
  }
}
