import { get, set } from 'lib/storage/versionedLocalStorageUtils'
import { get as getSS, set as setSS } from 'lib/storage/isomorphicSessionStorage'

const STORAGE_KEY = 'carHireReengagementCardDismissal'
const STORAGE_VERSION = 1

const SESSION_LOG_KEY = 'carHireReengagementSessionLogged'

interface CarHireReengagementDismissalState {
  isDismissed: boolean;
  dismissedAt: number;
  sessionsSinceDismissal: number;
  totalClosures: number;
}

const initialState: CarHireReengagementDismissalState = {
  isDismissed: false,
  dismissedAt: 0,
  sessionsSinceDismissal: 0,
  totalClosures: 0,
}

export function setCarHireReengagementDismissedInStorage(type: 'closed' | 'clicked') {
  const state = getCarHireReengagementDismissalState()
  // Don't count clicks toward total closures
  const totalClosures = type === 'closed' ? state.totalClosures + 1 : state.totalClosures
  setCarHireReengagementDismissalState({
    isDismissed: true,
    dismissedAt: Date.now(),
    sessionsSinceDismissal: 0,
    totalClosures,
  })
  setSS(SESSION_LOG_KEY, true)
}

const threeMonthsMilliseconds = 7889238000

/**
 * If the car hire reengagement card has been dismissed for the first time,
 * show it again in the next session, once dismissed for the second time
 * do not show until 3 months
 */
export function getDismissalStateAndResetIfExpired() {
  const state = getCarHireReengagementDismissalState()
  if (
    state.isDismissed &&
    state.sessionsSinceDismissal == 1 &&
    state.totalClosures == 1
  ) {
    const newState = {
      ...state,
      isDismissed: false,
      dismissedAt: 0,
    }
    setCarHireReengagementDismissalState(newState)
    return newState
  }

  if (
    state.totalClosures >= 2 &&
    Date.now() - state.dismissedAt > threeMonthsMilliseconds
  ) {
    const newState = {
      ...state,
      isDismissed: false,
      dismissedAt: 0,
      totalClosures: 0,
    }
    setCarHireReengagementDismissalState(newState)
    return newState
  }
  return state
}

/**
 * This function uses sessionstorage to track whether a session has been logged.
 * This is used to count the number of sessions since the card was dismissed.
 */
export function logSessionSinceCarHireReengagementDismissal() {
  const sessionLogged = getSS(SESSION_LOG_KEY)
  if (!sessionLogged) {
    setSS(SESSION_LOG_KEY, true)
    const state = getCarHireReengagementDismissalState()
    if (state.isDismissed) {
      setCarHireReengagementDismissalState({
        ...state,
        sessionsSinceDismissal: state.sessionsSinceDismissal + 1,
      })
    }
  }
}

function setCarHireReengagementDismissalState(state: CarHireReengagementDismissalState) {
  return set(STORAGE_KEY, STORAGE_VERSION, state)
}

function getCarHireReengagementDismissalState(): CarHireReengagementDismissalState {
  return get(STORAGE_KEY, STORAGE_VERSION) ?? initialState
}
