import Group from 'components/utils/Group'
import React, { PropsWithChildren, ReactNode } from 'react'

type Props = PropsWithChildren<{
  callToAction?: ReactNode
}>

function StickyPromptCardFooter(props: Props) {
  const {
    children,
    callToAction,
  } = props

  return <Group direction="horizontal" gap={12} horizontalAlign="space-between" verticalAlign="end">
    <div>{children}</div>
    {!!callToAction && <div>{callToAction}</div>}
  </Group>
}

export default StickyPromptCardFooter
