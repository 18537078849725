import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function SolidCheckIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M18.7 7.2c-.4-.4-1-.4-1.4 0l-7.5 7.5-3.1-3.1c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l3.8 3.8c.2.2.4.3.7.3.3 0 .5-.1.7-.3l8.2-8.2c.4-.4.4-1 0-1.4Z"/>
  </SvgIcon>
}

export default SolidCheckIcon
