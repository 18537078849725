import { disableStickyPromptCardWithCooldown } from 'actions/StickyPromptCardActions'
import { searchPaneInteractEventName } from 'components/Search/utils'
import config from 'constants/config'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import useToggle from 'hooks/useToggle'
import React, { useCallback, useEffect } from 'react'
import TripReengagementCard from 'tripPlanner/components/external/TripReengagementCard'
import useTripReengagementSelectionLogic from './useTripReengagementSelectionLogic'
import useCarHireReengagementSelectionLogic from './useCarHireReengagementSelectionLogic'
import CarHireReengagementCard from 'components/CarHire/CarHireReengagementCard'

interface Props {
  hotLead?: boolean
  tripReengagement?: boolean
  stickyTop?: number
  carHire?: boolean
}

const ENTRY_DELAY_MS = 2000

// disable all cards for some time after dismissal
const GLOBAL_COOLDOWN_AFTER_DISMISS_MS = config.STICKY_PROMPT_CARD_DISMISS_COOLDOWN_MS ?? 0

function PromptCardSelector({
  tripReengagement: tripReengagementCardEnabledProp = false,
  carHire: carHireReengagementCardEnabledProp = false,
  stickyTop,
}: Props) {
  // Tracks whether any card has been dismissed from this component instance
  const { value: cardDismissedFromHere, on: setCardDismissedFromHere } = useToggle(false)

  const { value: enabled, off: disable } = useToggle(true)

  // Hide the card if the user uses the search bar
  useEffect(() => {
    const handler = () => {
      disable()
    }
    document.addEventListener(searchPaneInteractEventName, handler)
    document.addEventListener('app-snackbar', handler)
    return () => {
      document.removeEventListener(searchPaneInteractEventName, handler)
      document.removeEventListener('app-snackbar', handler)
    }
  }, [disable])

  // TP and CH card need user login, HL does not
  const tripReengagementCardEnabled = tripReengagementCardEnabledProp && enabled
  const carHireReengagementCardEnabled = carHireReengagementCardEnabledProp && enabled

  const {
    isTripDataReady,
    canShowTripCard,
    setTripCardDismissed,
    trip,
  } = useTripReengagementSelectionLogic({ tripReengagementCardEnabled })

  const {
    carHireReengagementView,
    setCarHireCardDismissed,
    canShowCarHireCard,
    isCarHireDataReady,
  } = useCarHireReengagementSelectionLogic({ carHireReengagementCardEnabled })

  // For each card that's enabled, make sure we have the required data
  const readyToDecideCard = (
    (isCarHireDataReady || !carHireReengagementCardEnabled) &&
    (isTripDataReady || !tripReengagementCardEnabled)
  )

  // Initial delay before rendering the card
  const { value: isDelayed, off: finishDelay } = useToggle(true)
  useEffect(() => {
    const timeout = setTimeout(finishDelay, ENTRY_DELAY_MS)
    return () => clearTimeout(timeout)
  }, [finishDelay])

  const isStickyCardEnabled = useAppSelector(state => state.stickyPromptCard.isEnabled)

  const canShowAnyCard = readyToDecideCard && !cardDismissedFromHere && !isDelayed && isStickyCardEnabled
  const shouldShowCarHireCard = (
    canShowAnyCard &&
    canShowCarHireCard
  )
  const shouldShowTripCard = (
    canShowAnyCard &&
    canShowTripCard &&
    !shouldShowCarHireCard
  )
  const dispatch = useAppDispatch()

  const handleDismiss = useCallback(() => {
    dispatch(disableStickyPromptCardWithCooldown(GLOBAL_COOLDOWN_AFTER_DISMISS_MS))

    setCardDismissedFromHere()
  }, [dispatch, setCardDismissedFromHere])

  const onCloseTripCard = useCallback(() => {
    setTripCardDismissed()
    handleDismiss()
  }, [handleDismiss, setTripCardDismissed])

  const onCloseCarHireCard = useCallback(() => {
    setCarHireCardDismissed()
    handleDismiss()
  }, [handleDismiss, setCarHireCardDismissed])

  return <>
    {!!trip && <TripReengagementCard
      trip={trip}
      onClose={onCloseTripCard}
      show={shouldShowTripCard}
      stickyTop={stickyTop}
    />}
    {!!carHireReengagementView.offerSummary && <CarHireReengagementCard
      carHireReengagementView={carHireReengagementView}
      offerSummary={carHireReengagementView.offerSummary}
      onClose={onCloseCarHireCard}
      stickyTop={stickyTop}
      show={shouldShowCarHireCard}
    />}
  </>
}

export default PromptCardSelector
